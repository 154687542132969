import React, { useState } from "react";
import styled from "styled-components";
import { Grid, ButtonBase, Modal, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { trackCustomEvent } from "gatsby-plugin-google-gtag";

import { Layout, TopNavigation, FloatingPlayIcon } from "components";
import { VideoDetailNavigation } from "components/TopNavigation";
import VideoPlayer from "components/VideoPlayer";
import cover from "./images/cover.jpeg";

const isLive = false;

const seo = {
  title: "Ninety Five Senses",
  description: ``,
};

const ParaLg = styled.p`
  font-size: 1.5em;
  font-weight: 600;
  text-align: left;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    position: absolute;
    max-width: 60vw;
    left: 4rem;
    font-size: 1.5em;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 50vw;
    left: 8rem;   font-size: 1.75em;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 40vw;
    left: 18rem;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    left: 20rem;
  }
`;

const PageContainer = styled.div`
  background-color: #000;
  color: #fff;
  font-size: 1rem;
`;

const Quote = styled.p`
  font-size: 1.35rem;
  margin-bottom: 1.5rem;
`;

const SectionPara = styled.p`
  text-align: center;
  margin: 0;
`;

const useStyles = makeStyles((theme) => ({
  coverImage: {
    maxHeight: "calc(100vh - 65px)",
    objectFit: "cover",
    objectPosition: "top",
  },
  publicHearingImage: {
    maxHeight: "90vh",
    maxWidth: "50vw",
    objectFit: "contain",
  },
  publicHearingImageContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "end",
    },
  },
  sectionPadding: {
    padding: "1rem",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem 4rem",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "2rem 6rem",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "2rem 8rem",
    },
  },
  singleImage: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "50vw",
      maxHeight: "30vh",
    },
  },
  gridImages: {
    maxHeight: "50vw",
  },
  tiltedImageContainer: {
    padding: "2rem 0",
  },
  tiltedImage: {
    [theme.breakpoints.up("md")]: {
      margin: "3rem 0",
      transform: "rotate(-10deg) translate(-55px, -15px)",
    },
  },
  tiltedCaption: {
    textAlign: "left",
    fontSize: "1.5em",
    margin: 0,
    fontWeight: 600,
  },
  fullsizeImg: {
    maxHeight: "80vh",
    objectFit: "cover",
  },
  textLg: {
    fontSize: "1.5em",
    fontWeight: 600,
  },
  buttonLg: {
    backgroundColor: "#c0c6b5",
    marginBottom: "3rem",
    padding: "1rem 1.5rem",
    color: "#000",
    textDecoration: "none",
  },
  paper: {
    position: "absolute",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: "80vw",
    [theme.breakpoints.between(0, 600)]: {
      top: `50%`,
      left: `50%`,
      width: "100vw",
    },
  },
  modal: {
    backgroundColor: "black",
  },
  menuIcon: {
    color: theme.typography.color,
  },
  textRight: {
    textAlign: "right",
    padding: "0 4rem",
    cursor: "pointer",
  },
  noDecoration: {
    textDecoration: "none",
  },
  centered: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
  },
  premieringNotif: {
    padding: '1rem',
    border: '2px solid #fff',
    position: 'absolute',
    top: '50%',
    left: 'calc(50% - 125px)',
    borderRadius: 4,
    cursor: 'default'
  }
}));

const Sentence = ({ location }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleVideo = () => {
    setOpen(true);
  };

  return (
    <Layout location={location} seo={seo}>
      <TopNavigation />
      <PageContainer>
        <Box style={{ position: "relative", cursor: "pointer" }}>
          <FloatingPlayIcon
            float
            onClick={handleVideo}
            height={100}
            width={100}
          />
          <img
            src="https://res.cloudinary.com/dhg4fsecb/image/upload/c_scale,h_720,w_1280/v1706200794/Ninety-five_Senses_Poster_Horizontal_e2yhyv.jpg"
            className={`w-full ${classes.coverImage}`}
            alt="Ninety Five Senses"
          />
        </Box>
      </PageContainer>
      <Modal
        className={classes.modal}
        disableBackdropClick={true}
        open={open}
        onClose={() => {
          // trackCustomEvent({
          //   category: "video",
          //   action: "Close video",
          //   label: "Exit",
          // });
          setOpen(false);
        }}
        aria-labelledby="ninety-five-senses"
      >
        <div className={classes.paper}>
          <VideoPlayer
            mediaId="dDHLhWSP"
            url="https://cdn.jwplayer.com/manifests/dDHLhWSP.m3u8"
            autostart={true}
            title="Ninety Five Senses"
            setData={() => {}}
            open={open}
          />
        </div>
      </Modal>
      {(open && (
        <VideoDetailNavigation
          onClose={() => setOpen(false)}
          title="Ninety Five Senses"
        />
      )) ||
        null}
    </Layout>
  );
};

export default Sentence;
